.homepages-body {
  scroll-behavior: smooth;
  background: $background_main;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: none;

    @include media("<1200px") {
      width: 0;
      height: 0;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $background_main;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary_main;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary_main;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #fff;
    font-weight: normal;
  }

  img {
    max-width: 100%;
  }

  .ant-notification-hook-holder, .ant-notification-notice {
    background: $dark;
    border-radius: 8px;

  }
  .ant-notification-notice-message {
    margin-bottom: 0 !important;
  }

  .ant-notification-notice-close {
    color: #fff;

  }

  .ant-notification-notice-message {
    color: #fff !important;
    font-size: 12px;
    font-family: $mainSansSerif;
  }
}

.homepages {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: $mainSansSerif;
  background: $background_main;
  color: #fff;
  font-size: 16px;
  padding-left: 285px;
  position: relative;

  @include media("<1200px") {
    padding-left: 0;
    font-size: 14px;
  }

  &__content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    position: relative;
    z-index: 2;

    > * {
      width: 100%;
    }

    @include media("<1440px") {
      padding: 40px 20px;
    }

    @include media("<1200px") {
      padding: 20px 10px;
      padding-top: 100px;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    span {
      position: absolute;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &:nth-of-type(1) {
        width: 1914.72px;
        height: 1551.34px;
        right: 0;
        top: 700px;
        background-image: url("https://income-front-data.s3.fr-par.scw.cloud/justin/images/shadow_1.svg");
        background-position: right;
      }

      &:nth-of-type(2) {
        width: 1811px;
        height: 1811px;
        left: -600px;
        top: 2200px;
        background-image: url("https://income-front-data.s3.fr-par.scw.cloud/justin/images/shadow_2.svg");
        background-position: center;

        @include media("<768px") {
          top: 2600px;
        }
      }
    }
  }

  &--auth {
    .page-content {
      margin:  auto 0;

      @include media("<1200px") {
        margin: 0;
      }

    }
    .homepage-footer {
      margin: 0;
    }
  }
}

.auth-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 300px;
  overflow: hidden;
  z-index: -1;
  display: flex;
  align-items: center;
  
  @include media("<768px") {
    display: none;
  }

  img {
    max-width: 872px;
    position: relative;
    top: -50px;
  }

}

.homepage-sidebar {
  background: #14141F;
  position: fixed;
  left: 0;
  top: 0;
  width: 285px;
  box-shadow: 11.811px 11.811px 59.0551px rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: all .3s ease;
  height: 100%;
  @include media("<1200px") {
    width: 100%;
    transform: translateX(-100%);
    opacity: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px;
    padding-bottom: 20px;
    overflow-y: auto;

    @include media("<1200px") {
      padding: 0 10px 20px;
    }
  }

  &__top {
    display: flex;
    align-items: center;

    @include media("<1200px") {
      min-height: 60px;
    }
  }

  &__logo {
    @include media("<1200px") {
      width: 120px;
      height: 40px;
      img {
        height: 100%;
        object-fit: contain;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &__mobile-buttons {
    display: none;

    @include media("<1200px") {
      display: flex;
      padding-bottom: 30px;

      > * {
        + * {
          margin-left: 15px;
        }

        flex: 1;
      }
    }
  }

  &.opened {
    transform: translateX(0);
    opacity: 1;
  }
}
.homepages-nav {
  padding: 40px 0;
  margin: 0 -40px;
  margin-bottom: auto;

  @include media("<1200px") {
    margin: 0 -10px;
    padding: 20px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a, button {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 35px;
    padding: 10px 40px;
    border: none;
    background: none;
    cursor: pointer;
    transition: all .2s ease;
    font-family: $mainSansSerifMedium;

    @include media("<1200px") {
      padding: 10px 10px;
    }

    path {
      transition: all .2s ease;

    }

    .icon {
      display: flex;
      align-items: center;
      width: 32px;
      margin-right: 15px;
    }

    &.active {
      color: $primary_main;

      path {
        fill: $primary_main;
      }
    }

    &.highlighted {
      background: $primary_gradient;
      color: #000;

      path {
        fill: #000;
      }
    }
  }
}

.social-contacts {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 20px;
  margin-top: auto;
  display: flex;

  li {
    +li {
      margin-left: 10px;
    }
  }


  a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#FFF613, .25);
    border-radius: 8px;
    transition: all .2s ease;

    &:hover {
      background: rgba(#FFF613, .45);

    }
  }
}

.copyright {
  font-size: 11px;
}

.section-title {
  color: #fff;
  font-family: $mainSansSerifBold;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 30px;

  @include media("<1200px") {
    font-size: 28px;
    margin-bottom: 20px;
  }


  &--small-indent {
    margin-bottom: 10px;
  }
}

.custom-section {
  position: relative;
  margin-top: 90px;

  @include media("<1200px") {
    margin-top: 50px;
  }
  .section-anchor{
    position: absolute;
    left: 0;
    top: -50px;
    z-index: -1;

    @include media("<1200px") {
      top: -80px;
    }
  }
}

.plan-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: calc(50% - 16px);

    @include media("<1200px") {
      width: calc(50% - 5px);
    }

    @include media("<768px"){
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.plan-card {
  background: $background_main;
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  padding: 50px;
  transition: all .2s ease;
  display: flex;
  flex-direction: column;

  @include media("<1200px") {
    padding: 20px 15px;
  }

  &__title {
    font-size: 30px;
    font-family: $mainSansSerifMedium;
    margin-bottom: 10px;

    @include media("<1200px") {
      font-size: 21px;
    }
  }

  &__text {
    font-size: 16px;
    margin-bottom: 0;
    font-family: $mainSansSerif;
    font-weight: normal;

    @include media("<1200px") {
      font-size: 14px;
    }
  }

  &__list {
    padding: 0;
    padding-top: 35px;
    padding-bottom: 25px;


    @include media("<1200px") {
      font-size: 12px;
    }
    li {
      + li {
        margin-top: 25px;
      }

      display: flex;
      align-items: center;

      img {
        max-width: 20px;
        flex: none;
        margin-right: 25px;
        object-fit: contain;
      }
    }
  }

  .homepage-button {
    width: 200px;

    @include media("<1200px") {
      width: 100%;
    }
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.75);

    @include media("<1200px") {
      transform: none;
    }
  }
}

.homepage-button {
  font-size: 16px;
  color: #000;
  padding: 15px 40px;
  line-height: 20px;
  border-radius: 55px;
  background: $primary_main;
  border: none;
  text-align: center;
  font-family: $mainSansSerifMedium;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: all .2s ease;
  display: inline-block;
  font-weight: 600;

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    color: #000;
    background: lighten($primary_main, 10%);
  }

  &:active {
    transform: scale(0.98);
  }


  &--small {
    padding: 10px 30px;
    font-size: 14px;
  }

  &--secondary {
    background: #14141F;
    color: #fff;
  }
  &--gradient {
    &::before {
      opacity: 1;
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
}

.platforms-section {
  position: relative;
  display: flex;
  align-items: center;

  @include media("<768px") {
    flex-direction: column;
    text-align: center;
  }

  &__text {
    max-width: 370px;
    font-size: 16px;
  }

  &__image {
    max-width: 650px;
    position: relative;

    @include media("<1440px") {
      max-width: 450px;
    }

    @include media("<768px") {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }
  }
}

.faq-menu {
  padding: 0;
  list-style: none;
  display: flex;
  @include media("<768px") {
    display: block;
  }
  li {
    + li {
      margin-left: 25px;
      @include media("<1440px") {
        margin-left: 15px;
      }
      @include media("<768px") {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  button {
    width: 100%;
    display: block;
    padding: 20px 35px;
    line-height: 35px;
    font-size: 20px;
    font-family: $mainSansSerifBold;
    border: none;
    background: transparent;
    border: 2px solid $primaryColor;
    border-radius: 15px;
    cursor: pointer;
    overflow: hidden;
    transition: all .2s ease;
    position: relative;

    @include media("<1440px") {
      font-size: 18px;
      padding: 15px 20px;
    }

    @include media("<768px") {
      font-size: 16px;
      padding: 10px 15px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $secondary_gradient;
      opacity: 0;
      transition: all .2s ease;
    }

    &.active {
      background: $primary_main;
      color: #000;
      &:hover {
        color: #000;
        &::before {
          opacity: 0;
        }
      }
    }

    &:hover {
      color: #000;
      &::before {
        opacity: 1;
      }
    }

    &:active {
      transform: scale(0.96);
    }

    span {
      position: relative;
      z-index: 2;
    }
  }
}

.faq-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  padding-top: 30px;

}

.faq-item {
  width: calc(50% - 25px);
  margin-bottom: 35px;
  @include media("<768px") {
    width: 100%;
  }
  &__title {
    font-family: $mainSansSerifBold;
    font-size: 20px;
    margin-bottom: 10px;

    @include media("<1440px") {
      font-size: 18px;
    }
    @include media("<768px") {
      font-size: 16px;
    }
  }

  &__text {
    max-width: 600px;
    font-size: 14px;
    line-height: 1.6;
    margin: 0;

    @include media("<1440px") {
      font-size: 12px;
    }
    @include media("<768px") {
      font-size: 10px;
    }
  }
}

.contact-form {
  border-radius: 15px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
  background: $background_main;
  padding: 45px 60px;
  position: relative;

  @include media("<1200px") {
    padding: 30px 15px;
  }

  &__title {
    font-size: 24px;
    margin: 0;
    font-family: $mainSansSerifBold;

    @include media("<768px") {
      max-width: 230px;
      position: relative;
      z-index: 2;
    }
  }

  &__text {
    color: #fff;
    font-size: 16px;
    font-family: $mainSansSerif;
    margin: 0;

    @include media("<768px") {
      max-width: 230px;
      position: relative;
      z-index: 2;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding-top: 30px;
    position: relative;
    z-index: 2;

    @include media("<768px") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item + &__item {
    margin-left: 30px;
    @include media("<768px") {
      margin-left: 0;
      margin-top: 30px;
    }
  }

  &__item {
    margin-bottom: 0;
    width: 200px;

    @include media("<768px") {
      width: 100%;

      .homepage-button {
        width: 100%;
      }
    }
  }

  &__image {
    position: absolute;
    right: -35px;
    bottom: -15px;
    max-width: 385px;

    @include media("<1440px") {
      max-width: 250px;
    }

    @include media("<1200px") {
      right: -70px;
    }
    @include media("<768px") {
      bottom: auto;
      top: -50px;
      max-width: 200px;
    }
    img {
      max-width: 100%;
    }
  }

  .ant-input {
    height: 50px;
    border-radius: 10px;
  }

  .ant-form-item-explain {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    font-size: 12px;
  }
}

.homepage-footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding-top: 50px;

  @include media("<1200px") {
    flex-direction: column-reverse;

  }
}

.footer-logo {
  margin-left: auto;

  @include media("<1200px") {
    margin: 0 auto 20px;
  }

  img {
    max-width: 100%;
  }
}

.footer-nav {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  @include media("<1200px") {
    flex-direction: column;
    text-align: center;
  }

  li {
    + li {
      margin-left: 20px;

      @include media("<1200px") {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  button {
    cursor: pointer;
    border: none;
    padding: 0;
    background: none;
    opacity: .5;
    transition: all .2s ease;
    font-family: $mainSansSerifMedium;

    &:active {
      transform: scale(.95);
    }

    &:hover {
      opacity: 1;
    }
  }
}

.main-banner-section {
  margin: -40px -50px;
  margin-top: -80px;
  margin-bottom: 0;

  @include media("<1440px") {
    margin: -40px -20px;
    margin-top: -80px;
    margin-bottom: 0;
  }

  @include media("<1200px") {
    margin: 0 -10px;
    margin-top: -40px;
  }

  .slick-dots {
    bottom: 60px;

    li {
      width: auto;

      button {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 29px;
        width: 40px;
        height: 5px;
        padding: 0;
        transition: all .2s ease;

        &::before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background: $primary_main;
        }
      }
    }
  }
}

.main-banner {
  height: 670px;
  padding: 40px 50px;
  padding-top: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @include media("<1440px") {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media("<768px") {
    height: 500px;
    padding-top: 50px;
  }

  &__content {
    position: relative;
    z-index: 2;

    > * {
      text-shadow:  0 0 40px #000;
    }
  }

  &__title {
    font-size: 50px;
    line-height: 1.2;
    font-family: $mainSansSerifBold;
    max-width: 425px;
    margin-bottom: 15px;

    @include media("<768px") {
      font-size: 28px;
      max-width: 300px;
    }
  }

  &__text {
    font-size: 16px;
    max-width: 350px;
    font-family: $mainSansSerif;

    @include media("<768px") {
      font-size: 12px;
      max-width: 200px;
    }
  }

  &__buttons {
    padding-top: 60px;

    @include media("<768px") {
      padding-top: 40px;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
    }

    &::before {
      width: 0;
      height: 100%;
      box-shadow: 0 0 500px 350px $background_main;

      @include media("<768px") {
        box-shadow: 0 0 300px 150px #3c059b;
      }
    }

    &::after {
      height: 0;
      width: 100%;
      box-shadow: 0 0 100px 130px $background_main;
      @include media("<768px") {
        box-shadow: 0 0 80px 80px $background_main;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;

    }
  }
}

.games-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  @include media("<1440px") {
    margin: 0 -5px;
  }

  &__item {
    padding: 0 16px;
    width: 25%;

    @include media("<1440px") {
      padding: 0 5px;
    }

    @include media("<768px") {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}

.game-card {
  height: 325px;
  position: relative;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  align-items: flex-end;
  transition: all .2s linear;

  @include media("<768px") {
    height: 300px;
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 12px;
    }

    &::before {
      content: "";
      background: linear-gradient(180deg, rgba(60, 5, 155, 0) 0%, #000 71.31%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 10px;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 30px 15px;


    @include media("<768px") {
      padding: 15px 10px;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1;
    font-family: $mainSansSerifBold;
    margin-bottom: 5px;

    @include media("<1200px") {
      font-size: 16px;
    }
  }

  &__text {
    font-size: 12px;
    font-family: $mainSansSerif;
    margin: 0;
  }

  &__label {
    z-index: 10;
    position: absolute;
    left: 15px;
    top: 20px;
    border-radius: 50px;
    padding: 0 10px;
    line-height: 20px;
    font-size: 12px;

    @include media("<768px") {
      left: 10px;
      top: 10px;
    }
  }

  .homepage-button {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    margin-top: 15px;
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: 0px 10px 20px rgba(0, 0, 54, 0.5);
    @include media("<768px") {
      transform: none;
    }
  }
}

.games-cards-section {
  margin-top: -30px;
  position: relative;
  z-index: 999;
}

.game-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  padding: 20px 0;
  padding-left: 285px;
  overflow-y: auto;

  @include media("<1200px") {
    padding-left: 0;
    padding-top: 80px;
  }

  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, .5);
    backdrop-filter: blur(5px);
  }

  &__content {
    width: 320px;
    max-width: 90%;
    margin: auto;
    position: relative;
    z-index: 2;

    video {
      max-width: 100%;
      border-radius: 15px;
      box-shadow: 0 0 30px -5px #000;
      object-fit: cover;
    }
  }

}


.homepage-header {
  display: flex;
  align-items: center;
  z-index: 99;
  width: 100%;
  margin-top: -40px;
  height: 80px;

  @include media("<1200px") {
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0 10px;
    background: $background_main;
    box-shadow: 0 0 20px -3px rgba(0,0,0, .5);
    z-index: 9999;
  }

  &__buttons {
    display: flex;
    margin-left: 30px;
    @include media("<1200px") {
      display: none;
    }

    > * {
      + * {
        margin-left: 15px;
      }
    }
  }
}

.header-logo {
  display: none;

  @include media("<1200px") {
    display: block;
    margin-right: auto;
    width: 120px;
    height: 40px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  img {
    max-width: 100%;
  }
}

.burger-btn {
  display: none;
  background: none;
  width: 35px;
  height: 25px;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: all .2s ease;

  @include media("<1200px") {
    display: flex;
    margin-left: 20px;

  }

  span {
    height: 2px;
    width: 100%;
    border-radius: 50px;
    background: #fff;
    transition: all .2s ease;

    + span {
      margin-top: auto;
    }
  }


  &:active {
    transform: scale(.8);
  }
}

.close-button {
  width: 35px;
  height: 35px;
  margin-left: auto;
  border: none;
  background: none;
  cursor: pointer;
  display: none;
  transition: all .2s ease;
  position: relative;

  @include media("<1200px") {
    display: block;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 50px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:active {
    transform: scale(.8);
  }
}
.page-description-text {
  max-width: 370px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.offer-card {
  margin-top: 50px;
  padding: 35px 45px;
  background: $background_main;
  box-shadow: 0px 10px 37px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  align-items: flex-start;

  @include media("<768px") {
    display: block;
    padding: 20px;
    margin-top: 25px;
  }

  &__side {
    flex: none;
    width: 205px;
    margin-right: 35px;

    @include media("<768px") {
      width: 100%;
      margin-right: 0;
      margin-bottom: 25px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .homepage-button {
      +.homepage-button {
        margin-top: 10px;
      }
      width: 100%;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    height: 115px;
    background: #2E007D;
    border-radius: 10px;
    margin-bottom: 25px;

    img {
      max-height: 100%;
    }
  }

  &__title {
    font-size: 30px;
    line-height: 1;
    font-family: $mainSansSerifBold;
    margin-bottom: 15px;

    @include media("<768px") {
      font-size: 21px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px;

    @include media("<768px") {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;

    @include media("<768px") {
      font-size: 12px;
    }

    li {
      +li {
        margin-top: 5px;
      }
      padding-left: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        width: 4px;
        height: 4px;
        background: #fff;
        border-radius: 50%;

        @include media("<768px") {
          top: 7px;
        }
      }
    }
  }
  &__content {
    flex: 1;
  }
}

.terms-page {
  .section-title {
    text-align: center;
  }
}

.terms-content {
  font-size: 14px;
  margin-top: 20px;
}

.homepage-form {
  max-width: 360px;
  color: #fff;

  @include media("<768px") {
    max-width: 100%;
  }

  a {
    color: $primary_main;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .homepage-button {
    width: 100%;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .ant-input,
  .ant-input-affix-wrapper > input.ant-input {
    background: #fff !important;
    border: none !important;
    border-radius: 8px;
    padding-right: 50px;
    padding-left: 15px;
    color: #000;
    font-size: 14px;
    font-family: $mainSansSerif;
  }

  .ant-input-affix-wrapper {
    background: none !important;
    border-radius: 0;
    border: none;
    padding: 0;
    box-shadow: none;

    .ant-input-suffix {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 21px;
      
      .anticon {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .ant-checkbox-wrapper {
    color: #fff;
    font-size: 14px;
    font-family: $mainSansSerif;
    a {
      color: $primary_main;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        background: transparent;
        border: 1px solid $primaryColor !important;
        border-radius: 4px;
      }
      &::after {
        border-color: $primaryColor !important;
      }

    }
  }
  .ant-form-item-explain {
    font-size: 10px;
    min-height: initial;
    margin-top: 4px;
    padding: 0;
  }
}

.form-plan-select {
  .ant-radio-group {
    display: flex;

    .ant-radio-wrapper {
      border: 2px solid $primary_main;
      flex: 1;
      text-align: center;
      line-height: 41px;
      font-size: 16px;
      color: #fff;
      margin: 0;
      transition: all .2s ease;
      &.ant-radio-wrapper-checked {
        background: $primary_main;
        color: #000;
      }
      .ant-radio {
        display: none;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

    }
  }
}

.language-dropdown {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: $mainSansSerifBold;
  cursor: pointer;
  margin-left: auto;

  @include media("<1200px") {
    font-size: 0;
  }

  &__flag {
    width: 36px;
    height: 24px;
    object-fit: cover;
    margin-left: 10px;
    @include media("<1200px") {
      margin-left: 0;
    }
  }
}

.languages-overlay {


  .ant-menu {
    border: none;
    background: $background_main;
    color: #fff;
    border-radius: 8px;
    margin-left: -16px;

    .ant-menu-item{
      &:hover {
        color: $primary_main;
      }
    }
  }
}