$primary_light: '';
$primary_main: #FFF613;
$primary_dark: '';

$secondary_main: #5826D1;
$secondary_dark: #2B0272;

$background_main: #14141F;


$primary_gradient: #FFF613;
$secondary_gradient: #FFF613;